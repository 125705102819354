/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { OrderBy, SearchPaging, Sorting } from 'types/inputTypes';
import { AUDIT } from 'utils/routingUtils';
import { getObjectUrlParams } from 'utils/stringUtils';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToasterInfo from 'components/common/toasterInfo';
import { baseErrorNotification, baseInfoNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { getContactUsMessage } from 'utils/i18nUtils';
import {
  AuditedCreative,
  AuditedCreatives,
  AuditedCreativeSearchCriteria,
  AuditedCreativeUpdateRequest,
} from 'types/audit/auditedCreativeTypes';
import { AuditTemplate } from 'types/audit/auditTemplateTypes';
import { getAuditTemplates } from 'graphql/audit/auditTemplateQueries';
import { createAuditTemplate } from 'graphql/audit/auditTemplateMutations';
import { getAuditedCreatives } from 'graphql/audit/auditedCreativeQueries';
import cloneDeep from 'lodash/cloneDeep';
import logger from 'utils/logger/logger';
import { User } from 'types/types';
import { getAllUsers } from 'graphql/queries';
import { updateAuditedCreative } from 'graphql/audit/auditedCreativeMutations';
import { buildAuditReportUrl } from 'components/features/private/auditReport/AuditReportProvider';

export const AuditContext = createContext({});

export const filtersInitialState = {
  [SearchParams.TEMPLATE_ID]: '',
  [SearchParams.CREATIVE_ID]: '',
};

export const buildAuditUrl = (input: {
  [SearchParams.TEMPLATE_ID]?: string | null;
  [SearchParams.CREATIVE_ID]?: string | null;
}): string => {
  const filters = {
    [SearchParams.TEMPLATE_ID]: input[SearchParams.TEMPLATE_ID] || filtersInitialState[SearchParams.TEMPLATE_ID],
    [SearchParams.CREATIVE_ID]: input[SearchParams.CREATIVE_ID] || filtersInitialState[SearchParams.CREATIVE_ID],
  };

  const urlParams = getObjectUrlParams(filters);

  return `/${AUDIT}?${urlParams}`;
};

export interface AuditContextProps {
  loading: boolean;
  error: boolean;
  items: AuditedCreative[];

  templates: AuditTemplate[];
  users: User[];

  templateCreating: boolean;
  onTemplateSelected: (id: string | null) => void;
  selectedTemplateId: string | null;
  selectedCreative: AuditedCreative | null;

  order: OrderBy;
  setOrder: (value: OrderBy) => void;

  onApplyTemplate: (url: string, adsNumber: number, name: string) => void;
  onOpenReport: () => void;
  onSetSelectedCreative: (id: string | null) => void;
  onUpdateCreative: (creative: AuditedCreative) => void;

  pagination: AuditPaginationProps;
}

export const paginationInitialState = {
  page: 0,
  perPage: 100,
  pageCount: 0,
  totalSize: 0,
};

export interface AuditPaginationProps {
  page: number;
  pageCount: number;
  perPage: number;
  totalSize: number;
  setPage: (value: number) => void;
  setPageCount: (value: number) => void;
  setPerPage: (value: number) => void;
  setTotalSize: (value: number) => void;
}

const AuditProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    searchParams: { [SearchParams.TEMPLATE_ID]: _templateId = filtersInitialState[SearchParams.TEMPLATE_ID] },
    searchParams: { [SearchParams.CREATIVE_ID]: _creativeId = filtersInitialState[SearchParams.CREATIVE_ID] },
  } = useCustomLocation();

  const {
    data: usersData,
    loading: userLoading,
    error: usersError,
  } = useQuery<{
    getAllUsers?: User[];
  }>(getAllUsers);
  const {
    data: dataTemplates,
    loading: loadingTemplates,
    error: errorTemplates,
  } = useQuery<{
    getAuditTemplates: AuditTemplate[];
  }>(getAuditTemplates);
  const [doGetAuditedCreatives, { data: dataCreatives, loading: loadingCreatives, error: errorCreatives }] =
    useLazyQuery<{
      getAuditedCreatives: AuditedCreatives;
    }>(getAuditedCreatives);
  const [doCreateAuditTemplate] = useMutation<{ createAuditTemplate?: AuditTemplate }>(createAuditTemplate);
  const [doUpdateAuditedCreative] = useMutation<{ updateAuditedCreative?: AuditedCreative }>(updateAuditedCreative);

  const [items, setItems] = useState<AuditedCreative[]>([]);
  const [order, setOrder] = useState(OrderBy.ShareDesc);

  const [templates, setTemplates] = useState<AuditTemplate[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(_templateId || null);
  const [templateCreating, setTemplateCreating] = useState(false);
  const [selectedCreative, setSelectedCreative] = useState<AuditedCreative | null>(null);

  const [page, setPage] = useState(paginationInitialState.page);
  const [perPage, setPerPage] = useState(paginationInitialState.perPage);
  const [totalSize, setTotalSize] = useState(paginationInitialState.totalSize);
  const [pageCount, setPageCount] = useState(paginationInitialState.pageCount);

  const users = useMemo(() => usersData?.getAllUsers || [], [usersData?.getAllUsers]);

  // builds URL search part from the internal state
  const urlParams = useMemo(
    () =>
      getObjectUrlParams({
        [SearchParams.TEMPLATE_ID]: selectedTemplateId || _templateId || filtersInitialState[SearchParams.TEMPLATE_ID],
        [SearchParams.CREATIVE_ID]:
          selectedCreative?.id || _creativeId || filtersInitialState[SearchParams.CREATIVE_ID],
      }),
    [_creativeId, _templateId, selectedCreative?.id, selectedTemplateId],
  );

  const showInfoNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="info" title={title} />, { ...baseInfoNotification });
  }, []);
  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);
  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const fetchCreatives = useCallback(
    (templateId: string) => {
      if (!templateId?.length) return;

      doGetAuditedCreatives({
        variables: {
          paging: {
            page,
            perPage,
          } as SearchPaging,
          sorting: { orderBy: order } as Sorting,
          searchCriteria: {
            templateId: templateId,
          } as AuditedCreativeSearchCriteria,
        },
      });
    },
    [doGetAuditedCreatives, order, page, perPage],
  );

  const onTemplateSelected = useCallback(
    (id: string | null) => {
      setSelectedTemplateId(id);
      setSelectedCreative(null);
      setOrder(OrderBy.ShareDesc);
      setPage(paginationInitialState.page);
      navigate(
        buildAuditUrl({
          [SearchParams.TEMPLATE_ID]: id || '',
          [SearchParams.CREATIVE_ID]: '',
        }),
      );

      if (id) {
        fetchCreatives(id);
      } else {
        setItems([]);
      }
    },
    [fetchCreatives, navigate],
  );

  const onApplyTemplate = useCallback(
    (url: string, adsNumber: number, name: string) => {
      setTemplateCreating(true);
      showInfoNotification(t('PRIVATE.USER.AUDIT.CREATE_TEMPLATE_STARTED'));

      doCreateAuditTemplate({ variables: { url, adsNumber, name } })
        .then((result) => {
          const template = result?.data?.createAuditTemplate;
          if (template) {
            setTemplates((prev) => {
              const newArray = cloneDeep(prev);
              newArray.unshift(template);
              return newArray;
            });
            onTemplateSelected(template.id);
            showSuccessNotification(t('PRIVATE.USER.AUDIT.CREATE_TEMPLATE_SUCCESS'));
          } else {
            logger.error(`Error creating audit template`);
            showErrorNotification();
          }
        })
        .catch((e) => {
          logger.error(`Error creating audit template: ${e}`);
          showErrorNotification();
        })
        .finally(() => setTemplateCreating(false));
    },
    [
      doCreateAuditTemplate,
      onTemplateSelected,
      showErrorNotification,
      showInfoNotification,
      showSuccessNotification,
      t,
    ],
  );

  const onOpenReport = useCallback(() => {
    if (!selectedTemplateId) return;
    navigate(buildAuditReportUrl({ [SearchParams.TEMPLATE_ID]: selectedTemplateId }));
  }, [navigate, selectedTemplateId]);

  const onSetSelectedCreative = useCallback(
    (id: string | null) => {
      if (!selectedTemplateId) return;

      if (id) {
        const creative = items.find((i) => i.id === id);
        if (creative) setSelectedCreative(creative);
      } else {
        setSelectedCreative(null);
      }
      navigate(
        buildAuditUrl({
          [SearchParams.TEMPLATE_ID]: selectedTemplateId,
          [SearchParams.CREATIVE_ID]: id,
        }),
      );
    },
    [items, navigate, selectedTemplateId],
  );

  const onUpdateCreative = useCallback(
    (creative: AuditedCreative) => {
      const toUpdate: AuditedCreativeUpdateRequest = {
        id: creative.id,

        // *************** MAIN ***************
        authorId: creative.authorId,
        ugc: creative.ugc,

        // *************** AD CONCEPT ***************
        memeTrend: creative.memeTrend,
        aiContent: creative.aiContent,
        popularFormats: creative.popularFormats,
        popularFormatsFirstSecondDifferent: creative.popularFormatsFirstSecondDifferent,
        popularFormatsFirstSecondDifferentPopularFormats: creative.popularFormatsFirstSecondDifferentPopularFormats,

        // *************** ASSET DETAILS ***************
        layout: creative.layout,
        singleAssetFormatType: creative.singleAssetFormatType,
        singleAssetType: creative.singleAssetType,
        singleAssetSubType: creative.singleAssetSubType,
        assetDetailsFirstSecondDifferent: creative.assetDetailsFirstSecondDifferent,
        assetDetailsFirstSecondDifferentAssetType: creative.assetDetailsFirstSecondDifferentAssetType,
        assetDetailsFirstSecondDifferentAssetSubType: creative.assetDetailsFirstSecondDifferentAssetSubType,
        assetFormat: creative.assetFormat,
        assetRatio: creative.assetRatio,
        videoLength: creative.videoLength,

        // *************** CONTENT ***************
        entertaining: creative.entertaining,
        educating: creative.educating,
        educatingApp: creative.educatingApp,
        educatingAppWholeApp: creative.educatingAppWholeApp,
        educatingAppService: creative.educatingAppService,
        educatingWiderTopics: creative.educatingWiderTopics,
        contentFirstSecondDifferent: creative.contentFirstSecondDifferent,
        contentFirstSecondDifferentEntertaining: creative.contentFirstSecondDifferentEntertaining,
        contentFirstSecondDifferentEducating: creative.contentFirstSecondDifferentEducating,
        contentFirstSecondDifferentEducatingApp: creative.contentFirstSecondDifferentEducatingApp,
        contentFirstSecondDifferentEducatingAppWholeApp: creative.contentFirstSecondDifferentEducatingAppWholeApp,
        contentFirstSecondDifferentEducatingAppService: creative.contentFirstSecondDifferentEducatingAppService,
        contentFirstSecondDifferentEducatingWiderTopics: creative.contentFirstSecondDifferentEducatingWiderTopics,
        trend: creative.trend,
        trendFirstSecondDifferent: creative.trendFirstSecondDifferent,
        trendFirstSecondDifferentTrend: creative.trendFirstSecondDifferentTrend,
        references: creative.references,
        sound: creative.sound,
        soundFirstSecondDifferent: creative.soundFirstSecondDifferent,
        soundFirstSecondDifferentSound: creative.soundFirstSecondDifferentSound,
        contentType: creative.contentType,
        contentTypeFirstSecondDifferent: creative.contentTypeFirstSecondDifferent,
        contentTypeFirstSecondDifferentContentType: creative.contentTypeFirstSecondDifferentContentType,
        financialBenefit: creative.financialBenefit,

        // *************** VISUAL FEATURES ***************
        brandedElements: creative.brandedElements,
        brandedElementsFirstSecondDifferent: creative.brandedElementsFirstSecondDifferent,
        brandedElementsFirstSecondDifferentBrandedElements: creative.brandedElementsFirstSecondDifferentBrandedElements,
        mainAd: creative.mainAd,
        mainAdFirstSecondDifferent: creative.mainAdFirstSecondDifferent,
        mainAdFirstSecondDifferentVisualFeatures: creative.mainAdFirstSecondDifferentVisualFeatures,
        endCard: creative.endCard,
        ctaPlacement: creative.ctaPlacement,

        // *************** SCRIPT/COPY ***************
        subtitles: creative.subtitles,
        subtitlesFirstSecondDifferent: creative.subtitlesFirstSecondDifferent,
        subtitlesFirstSecondDifferentSubtitles: creative.subtitlesFirstSecondDifferentSubtitles,
        languages: creative.languages,
        copyFeatures: creative.copyFeatures,
      };

      doUpdateAuditedCreative({ variables: { input: toUpdate } })
        .then((result) => {
          const updated = result?.data?.updateAuditedCreative;
          if (updated) {
            showSuccessNotification(t('PRIVATE.USER.AUDIT.CREATIVE_UPDATE_SUCCESS'));
            setItems((prev) => {
              const index = prev.findIndex(({ id }) => id === creative.id);
              const newValue = cloneDeep(prev);
              if (index !== -1) {
                newValue[index] = updated;
              }
              return newValue;
            });
          } else {
            logger.error(`Error updating creative ${creative.id}`);
            showErrorNotification();
          }
        })
        .catch((e) => {
          logger.error(`Error updating creative ${creative.id}: ${e}`);
          showErrorNotification();
        });
    },
    [doUpdateAuditedCreative, showErrorNotification, showSuccessNotification, t],
  );

  // set fetched templates
  useEffect(() => {
    if (!loadingTemplates && !errorTemplates) {
      setTemplates(dataTemplates?.getAuditTemplates || []);
    }
  }, [dataTemplates?.getAuditTemplates, errorTemplates, loadingTemplates]);

  // define displayed items
  useEffect(() => {
    let items: AuditedCreative[] = [];
    let totalSize = 0;
    if (!loadingCreatives && !errorCreatives) {
      items = dataCreatives?.getAuditedCreatives?.items || [];
      totalSize = dataCreatives?.getAuditedCreatives?.paging?.totalCount || 0;
    }
    setPageCount(Math.ceil(totalSize / perPage));
    setTotalSize(totalSize);
    setItems(items);
  }, [perPage, loadingCreatives, errorCreatives, dataCreatives?.getAuditedCreatives]);

  useEffect(() => {
    if (_creativeId) setSelectedCreative(items.find((i) => i.id === _creativeId) || null);
  }, [_creativeId, items]);

  useEffect(() => {
    if (!!selectedTemplateId?.length) {
      fetchCreatives(selectedTemplateId);
    }
  }, [page, order, selectedTemplateId, fetchCreatives]);

  useEffect(() => {
    navigate(`/${AUDIT}?${urlParams}`, { replace: true });
  }, [navigate, urlParams]);

  const value = {
    loading: loadingTemplates || loadingCreatives || userLoading,
    error: !!errorTemplates || !!errorCreatives || !!usersError,
    items,

    templates,
    users,

    templateCreating,
    onTemplateSelected,
    selectedTemplateId,
    selectedCreative,

    order,
    setOrder,

    onApplyTemplate,
    onOpenReport,
    onSetSelectedCreative,
    onUpdateCreative,

    pagination: {
      page,
      pageCount,
      perPage,
      totalSize,
      setPage,
      setPageCount,
      setPerPage,
      setTotalSize,
    },
  } as AuditContextProps;

  // console.log(value);
  return <AuditContext.Provider value={value}>{children}</AuditContext.Provider>;
};

export default AuditProvider;
