import { gql } from '@apollo/client';
import { fragments } from './queries';

export const businessFragments = {
  projectAndBrief: gql`
    fragment ProjectAndBrief on ProjectAndBrief {
      project {
        ...Project
      }
      brief {
        ...Brief
      }
    }
  `,
  projects: gql`
    fragment Projects on Projects {
      items {
        ...Project
      }
      paging {
        ...Paging
      }
    }
  `,
  project: gql`
    fragment Project on Project {
      clientId

      id
      paymentModel
      title
      deliveryDate
      ugc

      briefId

      creatorIds

      tikTokAdvertiserId

      createdDate
    }
  `,
  briefs: gql`
    fragment Briefs on Briefs {
      items {
        ...Brief
      }
      paging {
        ...Paging
      }
    }
  `,
  brief: gql`
    fragment Brief on Brief {
      projectId
      id

      paymentModel
      state

      main {
        ...BriefMain
      }
      concept {
        ...BriefConcept
      }
      deliverables {
        ...BriefDeliverables
      }
      ugc {
        ...BriefUgc
      }
      creators {
        ...BriefCreators
      }
      copy {
        ...BriefCopy
      }
      creative {
        ...BriefCreative
      }

      comments {
        ...BriefComment
      }

      createdDate
    }
  `,
  briefMain: gql`
    fragment BriefMain on BriefMain {
      title
      channel
      ugc
      deadlineForCreators
    }
  `,
  briefConcept: gql`
    fragment BriefConcept on BriefConcept {
      description
      adReferences
      productFeatures
      valuePropositions
      pastLearnings
      mandatoryElements
      materials {
        ...BriefConceptMaterial
      }
    }
  `,
  briefConceptMaterial: gql`
    fragment BriefConceptMaterial on BriefConceptMaterial {
      fileName
      content
    }
  `,
  briefDeliverables: gql`
    fragment BriefDeliverables on BriefDeliverables {
      creatorsNumber
      videosNumber
      layouts
      videoLength

      assetsVariationsNumber
      assetsVariationsTypes
      assetsVariationsMotionNumber
      assetsVariationsMotionTypes
      assetsVariationsStaticsNumber
      assetsVariationsStaticsTypes
    }
  `,
  briefUgc: gql`
    fragment BriefUgc on BriefUgc {
      script
      scriptTones
      scriptTonesOther
      requirementsForCreators
      materials {
        ...BriefConceptMaterial
      }
    }
  `,
  briefCreators: gql`
    fragment BriefCreators on BriefCreators {
      creatorsNumber
      locations
      locationOther
      languages
      languageOther
      genders
      age
      notes
      requirementsForCreators
    }
  `,
  briefCopy: gql`
    fragment BriefCopy on BriefCopy {
      copyText
      copyTones
      copyToneOther
    }
  `,
  briefCreative: gql`
    fragment BriefCreative on BriefCreative {
      visualFeatures
      mandatoryElements
    }
  `,
  briefComment: gql`
    fragment BriefComment on BriefComment {
      userId
      value
      dateTime
    }
  `,
  ads: gql`
    fragment Ads on Ads {
      items {
        ...Ad
      }
      paging {
        ...Paging
      }
    }
  `,
  ad: gql`
    fragment Ad on Ad {
      id
      pgId

      state
      internalState

      type
      title
      versions {
        ...AdVersion
      }
      history {
        ...AdHistoryEvent
      }

      deleted

      createdByUserId
      createdDate
    }
  `,
  adVersion: gql`
    fragment AdVersion on AdVersion {
      id
      name
      medias {
        ...AdMedia
      }
      comments {
        ...AdComment
      }
    }
  `,
  adMedia: gql`
    fragment AdMedia on AdMedia {
      imageKitName
      name
      type
      size
      mime
      height
      width
      ratio
      videoMarkers {
        ...AdMediaMarker
      }
      imageCanvas
      externalUrl
    }
  `,
  adMediaMarker: gql`
    fragment AdMediaMarker on AdMediaMarker {
      commentId
      time
      text
    }
  `,
  adComment: gql`
    fragment AdComment on AdComment {
      id
      parentId
      childrenIds
      value
      userId
      internalState
      mentionedUserIds
      createdDate
      lastModifiedDate
    }
  `,
  adCommentor: gql`
    fragment AdCommentor on AdCommentor {
      id
      firstName
      lastName
      profilePic
    }
  `,
  adHistoryEvent: gql`
    fragment AdHistoryEvent on AdHistoryEvent {
      createdByUserId
      createdByUserName
      createdDate
      oldState
      newState
      commentAdded
      commentUpdated
      commentDeleted
      internalState
    }
  `,
  imageKitFileDetails: gql`
    fragment ImageKitFileDetails on ImageKitFileDetails {
      fileType
      name
      fileId
      filePath
      size
      url
      mime
      height
      width
      originalName
    }
  `,
  adsDna: gql`
    fragment AdsDna on AdsDna {
      items {
        ...AdDna
      }
      paging {
        ...Paging
      }
    }
  `,
  adDna: gql`
    fragment AdDna on AdDna {
      id
      clientId
      projectId
      adId

      createdDate

      pgId
      pgIdAuthor

      # *************** MAIN ***************
      title
      paymentModel
      channel
      singleAssetFormatType
      singleAssetType
      singleAssetSubType

      # ************** GRAB ATTENTION ***************
      visualHookBranded
      visualHookFeatures
      visualHookUnusual
      visualHookRelevance
      copyHook
      copyHookPlain
      copyHookFraming
      copyHookTypology
      copyHookRelevance
      popularFormats
      references
      trend
      sound
      aiContent

      # ************** HOLD ATTENTION ***************
      ugcCreators
      visualBranded
      visualFeatures
      copy
      copyPlain
      painPoints
      personas {
        ...ClientPersona
      }
      benefits
      productFeatures
      valuePropositions
      educating
      educatingApp
      educatingAppWholeApp
      educatingAppService
      educatingWiderTopics
      userCentricity
      specificity
      statistics
      simpleLanguage
      legalDisclaimer
      subtitles
      entertaining
      anticipation

      # ************** EVOKE ACTION ***************
      financialBenefit
      instantValue
      socialProof
      authority
      lossAversion
      scarcity
      unity
      deeperMotivationalDesire
      sellTheProductSellTheFilling
      cta
      ctaInfo
      ctaPlacement

      # *************** AD CONCEPT ***************
      memeTrend
      licensedContent
      popularFormatsFirstSecondDifferent
      popularFormatsFirstSecondDifferentPopularFormats
      ctas
      layout
      assetRatio
      assetFormat
      assetDetailsFirstSecondDifferent
      assetDetailsFirstSecondDifferentAssetType
      assetDetailsFirstSecondDifferentAssetSubType
      videoLength
      slidesNumber

      # *************** ROLES ***************
      roles {
        ...AdDnaRole
      }

      accountManagerUserId
      accountManagerName
      accountManagerUserIds
      accountManagerNames

      creativeStrategistUserId
      creativeStrategistName
      creativeStrategistUserIds
      creativeStrategistNames

      copywriterUserId
      copywriterName
      copywriterUserIds
      copywriterNames

      graphicDesignerUserId
      graphicDesignerName
      graphicDesignerUserIds
      graphicDesignerNames

      motionDesignerUserId
      motionDesignerName
      motionDesignerUserIds
      motionDesignerNames

      illustratorUserId
      illustratorName
      illustratorUserIds
      illustratorNames

      modelUserId
      modelName
      modelUserIds
      modelNames

      photographerUserId
      photographerName
      photographerUserIds
      photographerNames

      videographerUserId
      videographerName
      videographerUserIds
      videographerNames

      creatorUserId
      creatorName
      creatorUserIds
      creatorNames

      # *************** SCRIPT/COPY ***************
      ugcScript
      ugcScriptPlain
      endCardCopy
      endCardCopyPlain
      languages
      languageOther
      scriptTones # copyTones
      scriptTonesOther # copyToneOther
      scriptVisualFeatures
      subtitlesFirstSecondDifferent
      subtitlesFirstSecondDifferentSubtitles

      # *************** CONTENT ***************
      firstSecondRelevance
      firstSecondRelevanceInfo
      bannerBlindness
      bannerBlindnessInfo
      culturalCurrency
      culturalCurrencyInfo
      entertain
      entertainInfo
      educateInform
      educateInformInfo
      mindfuckAnticipation
      mindfuckAnticipationInfo
      shortTermIncentives
      shortTermIncentivesInfo
      longTermIncentives
      longTermIncentivesInfo

      contentFirstSecondDifferent
      contentFirstSecondDifferentEntertaining
      contentFirstSecondDifferentEducating
      contentFirstSecondDifferentEducatingApp
      contentFirstSecondDifferentEducatingAppWholeApp
      contentFirstSecondDifferentEducatingAppService
      contentFirstSecondDifferentEducatingWiderTopics
      trendFirstSecondDifferent
      trendFirstSecondDifferentTrend
      soundFirstSecondDifferent
      soundFirstSecondDifferentSound
      contentType
      contentTypeFirstSecondDifferent
      contentTypeFirstSecondDifferentContentType
      brandedElements
      brandedElementsFirstSecondDifferent
      brandedElementsFirstSecondDifferentBrandedElements
      visualFeaturesFirstSecondDifferent
      visualFeaturesFirstSecondDifferentVisualFeatures
      endCard

      # *************** SM INSIGHTS - OVERALL ***************
      insightsSpend

      # app install
      insightsAppInstall
      insightsAppInstallCost

      # view content
      insightsViewContent
      insightsViewContentCost

      # add to cart
      insightsAddToCart
      insightsAddToCartCost

      # add to wishlist
      insightsAddToWishlist
      insightsAddToWishlistCost

      # registration
      insightsCompleteRegistration
      insightsCompleteRegistrationCost

      # purchase
      insightsPurchase
      insightsPurchaseCost

      # *************** SM INSIGHTS - META ***************
      insightsMetaSpend

      # app install
      insightsMetaAppInstall
      insightsMetaAppInstallCost

      # view content
      insightsMetaViewContent
      insightsMetaViewContentCost

      # add to cart
      insightsMetaAddToCart
      insightsMetaAddToCartCost

      # add to wishlist
      insightsMetaAddToWishlist
      insightsMetaAddToWishlistCost

      # registration
      insightsMetaCompleteRegistration
      insightsMetaCompleteRegistrationCost

      # purchase
      insightsMetaPurchase
      insightsMetaPurchaseCost

      # return on ad spend
      insightsMetaReturnOnAdSpend
      insightsMetaReturnOnAdSpendCost

      # *************** SM INSIGHTS - TIKTOK ***************
      insightsTiktokSpend

      # app install
      insightsTiktokAppInstall
      insightsTiktokAppInstallCost

      # view content
      insightsTiktokViewContent
      insightsTiktokViewContentCost

      # add to cart
      insightsTiktokAddToCart
      insightsTiktokAddToCartCost

      # add to wishlist
      insightsTiktokAddToWishlist
      insightsTiktokAddToWishlistCost

      # registration
      insightsTiktokCompleteRegistration
      insightsTiktokCompleteRegistrationCost

      # purchase
      insightsTiktokPurchase
      insightsTiktokPurchaseCost

      # ad score
      adScore
      adScoreMeta
      adScoreTiktok

      adScoreRegs
      adScoreRegsMeta
      adScoreRegsTiktok

      adScorePurchase
      adScorePurchaseMeta
      adScorePurchaseTiktok
    }
  `,
  adDnaRole: gql`
    fragment AdDnaRole on AdDnaRole {
      role
      userId
    }
  `,
  analytics: gql`
    fragment Analytics on Analytics {
      platformDetails {
        ...AnalyticsPlatformDetails
      }
      platformDetailsMeta {
        ...AnalyticsPlatformDetails
      }
      platformDetailsTiktok {
        ...AnalyticsPlatformDetails
      }

      topProjectsByAdSpend {
        ...AnalyticsProjectDetails
      }
      topProjectsByAdSpendMeta {
        ...AnalyticsProjectDetails
      }
      topProjectsByAdSpendTiktok {
        ...AnalyticsProjectDetails
      }

      topAdsByAdScore {
        ...AnalyticsAdDetails
      }
      topAdsByAdScoreMeta {
        ...AnalyticsAdDetails
      }
      topAdsByAdScoreTiktok {
        ...AnalyticsAdDetails
      }

      topAdsByAdScoreRegs {
        ...AnalyticsAdDetails
      }
      topAdsByAdScoreRegsMeta {
        ...AnalyticsAdDetails
      }
      topAdsByAdScoreRegsTiktok {
        ...AnalyticsAdDetails
      }

      topAdsByAdScorePurchase {
        ...AnalyticsAdDetails
      }
      topAdsByAdScorePurchaseMeta {
        ...AnalyticsAdDetails
      }
      topAdsByAdScorePurchaseTiktok {
        ...AnalyticsAdDetails
      }

      averageAdScoreByProductFeature {
        ...AnalyticsProductFeatureDetails
      }
      averageAdScoreByProductFeatureMeta {
        ...AnalyticsProductFeatureDetails
      }
      averageAdScoreByProductFeatureTiktok {
        ...AnalyticsProductFeatureDetails
      }

      averageAdScoreRegsByProductFeature {
        ...AnalyticsProductFeatureDetails
      }
      averageAdScoreRegsByProductFeatureMeta {
        ...AnalyticsProductFeatureDetails
      }
      averageAdScoreRegsByProductFeatureTiktok {
        ...AnalyticsProductFeatureDetails
      }

      averageAdScorePurchaseByProductFeature {
        ...AnalyticsProductFeatureDetails
      }
      averageAdScorePurchaseByProductFeatureMeta {
        ...AnalyticsProductFeatureDetails
      }
      averageAdScorePurchaseByProductFeatureTiktok {
        ...AnalyticsProductFeatureDetails
      }

      averageInstallsByProductFeature {
        ...AnalyticsProductFeatureDetails
      }
      averageInstallsByProductFeatureMeta {
        ...AnalyticsProductFeatureDetails
      }
      averageInstallsByProductFeatureTiktok {
        ...AnalyticsProductFeatureDetails
      }

      averageRegsByProductFeature {
        ...AnalyticsProductFeatureDetails
      }
      averageRegsByProductFeatureMeta {
        ...AnalyticsProductFeatureDetails
      }
      averageRegsByProductFeatureTiktok {
        ...AnalyticsProductFeatureDetails
      }

      averagePurchaseByProductFeature {
        ...AnalyticsProductFeatureDetails
      }
      averagePurchaseByProductFeatureMeta {
        ...AnalyticsProductFeatureDetails
      }
      averagePurchaseByProductFeatureTiktok {
        ...AnalyticsProductFeatureDetails
      }

      averageCostPerInstallByProductFeature {
        ...AnalyticsProductFeatureDetails
      }
      averageCostPerInstallByProductFeatureMeta {
        ...AnalyticsProductFeatureDetails
      }
      averageCostPerInstallByProductFeatureTiktok {
        ...AnalyticsProductFeatureDetails
      }

      averageCostPerRegByProductFeature {
        ...AnalyticsProductFeatureDetails
      }
      averageCostPerRegByProductFeatureMeta {
        ...AnalyticsProductFeatureDetails
      }
      averageCostPerRegByProductFeatureTiktok {
        ...AnalyticsProductFeatureDetails
      }

      averageCostPerPurchaseByProductFeature {
        ...AnalyticsProductFeatureDetails
      }
      averageCostPerPurchaseByProductFeatureMeta {
        ...AnalyticsProductFeatureDetails
      }
      averageCostPerPurchaseByProductFeatureTiktok {
        ...AnalyticsProductFeatureDetails
      }
    }
  `,
  analyticsAdDetails: gql`
    fragment AnalyticsAdDetails on AnalyticsAdDetails {
      pgId
      installs
      costPerInstall
      regs
      costPerReg
      purchases
      costPerPurchase
      adScore
    }
  `,
  analyticsPlatformDetails: gql`
    fragment AnalyticsPlatformDetails on AnalyticsPlatformDetails {
      averageAdScore
      averageAdScoreRegs
      averageAdScorePurchase
      adsCount
      ugcAdsCount
      nonUgcAdsCount
    }
  `,
  analyticsProjectDetails: gql`
    fragment AnalyticsProjectDetails on AnalyticsProjectDetails {
      projectId
      projectName
      adSpend
    }
  `,
  analyticsProductFeatureDetails: gql`
    fragment AnalyticsProductFeatureDetails on AnalyticsProductFeatureDetails {
      productFeature
      adScore
      installs
      costPerInstall
      regs
      costPerReg
      purchase
      costPerPurchase
    }
  `,
};

export const getProjects = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: ProjectSearchCriteria) {
    getProjects(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Projects
    }
  }
  ${businessFragments.projects}
  ${businessFragments.project}
  ${fragments.paging}
`;

export const getProjectsAndBriefs = gql`
  query ($clientId: String) {
    getProjectsAndBriefs(clientId: $clientId) {
      ...ProjectAndBrief
    }
  }
  ${businessFragments.projectAndBrief}
  ${businessFragments.project}
  ${businessFragments.brief}
  ${businessFragments.briefMain}
  ${businessFragments.briefConcept}
  ${businessFragments.briefConceptMaterial}
  ${businessFragments.briefDeliverables}
  ${businessFragments.briefUgc}
  ${businessFragments.briefCreators}
  ${businessFragments.briefCopy}
  ${businessFragments.briefCreative}
  ${businessFragments.briefComment}
`;

export const getProject = gql`
  query ($id: String!) {
    getProject(id: $id) {
      ...Project
    }
  }
  ${businessFragments.project}
`;

export const getBriefs = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: BriefSearchCriteria) {
    getBriefs(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Projects
    }
  }
  ${businessFragments.briefs}
  ${businessFragments.brief}
  ${businessFragments.briefMain}
  ${businessFragments.briefConcept}
  ${businessFragments.briefConceptMaterial}
  ${businessFragments.briefDeliverables}
  ${businessFragments.briefUgc}
  ${businessFragments.briefCreators}
  ${businessFragments.briefCopy}
  ${businessFragments.briefCreative}
  ${businessFragments.briefComment}
  ${fragments.paging}
`;

export const getBrief = gql`
  query ($id: String!) {
    getBrief(id: $id) {
      ...Brief
    }
  }
  ${businessFragments.brief}
  ${businessFragments.briefMain}
  ${businessFragments.briefConcept}
  ${businessFragments.briefConceptMaterial}
  ${businessFragments.briefDeliverables}
  ${businessFragments.briefUgc}
  ${businessFragments.briefCreators}
  ${businessFragments.briefCopy}
  ${businessFragments.briefCreative}
  ${businessFragments.briefComment}
`;

export const getAds = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: AdSearchCriteria) {
    getAds(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Ads
    }
  }
  ${businessFragments.ads}
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
  ${fragments.paging}
`;

export const getAd = gql`
  query ($id: String!) {
    getAd(id: $id) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const getAdCommentors = gql`
  query ($id: String!) {
    getAdCommentors(id: $id) {
      ...AdCommentor
    }
  }
  ${businessFragments.adCommentor}
`;

export const getAdContentAsBlob = gql`
  query ($id: String!) {
    getAdContentAsBlob(id: $id)
  }
`;

export const getApprovedAdsContentAsBlob = gql`
  query ($projectId: String!) {
    getApprovedAdsContentAsBlob(projectId: $projectId)
  }
`;

export const getAdsDna = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: AdDnaSearchCriteria) {
    getAdsDna(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...AdsDna
    }
  }
  ${businessFragments.adsDna}
  ${businessFragments.adDna}
  ${businessFragments.adDnaRole}
  ${fragments.clientPersona}
  ${fragments.paging}
`;

export const getAdDna = gql`
  query ($id: String!) {
    getAdDna(id: $id) {
      ...AdDna
    }
  }
  ${businessFragments.adDna}
  ${businessFragments.adDnaRole}
  ${fragments.clientPersona}
`;

export const getAnalytics = gql`
  query ($clientId: String!) {
    getAnalytics(clientId: $clientId) {
      ...Analytics
    }
  }
  ${businessFragments.analytics}
  ${businessFragments.analyticsAdDetails}
  ${businessFragments.analyticsPlatformDetails}
  ${businessFragments.analyticsProjectDetails}
  ${businessFragments.analyticsProductFeatureDetails}
`;
