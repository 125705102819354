import React from 'react';
import { ReactComponent as StarIcon } from 'assets/img/star.svg';
import { ReactComponent as StarFilledIcon } from 'assets/img/starFilled.svg';

import styles from './Rating.module.scss';
import { times } from 'lodash';
import clsx from 'classnames';

interface Props {
  value: number;
  max: number;
  onClick?: (value: number) => void;
}

export default function Rating({ value, max, onClick }: Props): JSX.Element {
  return (
    <div className={clsx(styles.rating, { [styles.readOnly]: !onClick })}>
      {times(max).map((i, index) => (
        <div
          key={index}
          className={styles.item}
          onClick={() => {
            if (onClick) onClick(i + 1);
          }}
        >
          {index + 1 <= value ? <StarFilledIcon /> : <StarIcon />}
        </div>
      ))}
    </div>
  );
}
