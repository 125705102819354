import { Maybe, Paging, Scalars } from './types';

export type AuditedCreatives = {
  items: Array<AuditedCreative>;
  paging: Paging;
};

export type AuditedCreative = {
  id: Scalars['String'];

  templateId: Scalars['String'];

  // *************** SENSOR TOWER ***************
  stAdUnitId: Scalars['String'];
  stAppId: Scalars['String'];
  stCreativeId: Scalars['String'];
  stNetwork: Scalars['String'];
  stPhashionGroup: Scalars['String'];
  stAdType: Scalars['String'];
  stFirstSeen: Scalars['String'];
  stLastSeen: Scalars['String'];
  stCreativeUrl: Scalars['String'];
  stPreviewUrl?: Maybe<Scalars['String']>;
  stThumbUrl: Scalars['String'];
  stWidth: Scalars['Int'];
  stHeight: Scalars['Int'];
  stTitle?: Maybe<Scalars['String']>;
  stMessage?: Maybe<Scalars['String']>;
  stVideoDuration?: Maybe<Scalars['Int']>;
  stShare?: Maybe<Array<Scalars['Float']>>;

  // *************** MAIN ***************
  authorId?: Maybe<Scalars['String']>;
  ugc?: Maybe<Scalars['Boolean']>;

  // *************** AD CONCEPT ***************
  memeTrend?: Maybe<Scalars['Boolean']>;
  aiContent?: Maybe<Scalars['Boolean']>;
  popularFormats?: Maybe<Array<AuditedCreativePopularFormat>>;
  popularFormatsFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  popularFormatsFirstSecondDifferentPopularFormats?: Maybe<Array<AuditedCreativePopularFormat>>;

  // *************** ASSET DETAILS ***************
  layout?: Maybe<AuditedCreativeLayout>;
  singleAssetFormatType?: Maybe<AuditedCreativeSingleAssetFormatType>;
  singleAssetType?: Maybe<AuditedCreativeSingleAssetType>;
  singleAssetSubType?: Maybe<AuditedCreativeSingleAssetSubType>;
  assetDetailsFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  assetDetailsFirstSecondDifferentAssetType?: Maybe<AuditedCreativeSingleAssetType>;
  assetDetailsFirstSecondDifferentAssetSubType?: Maybe<AuditedCreativeSingleAssetSubType>;
  assetFormat?: Maybe<AuditedCreativeType>;
  assetRatio?: Maybe<Scalars['String']>;
  videoLength?: Maybe<Scalars['Int']>;

  // *************** CONTENT ***************
  entertaining?: Maybe<Scalars['Boolean']>;
  educating?: Maybe<Scalars['Boolean']>;
  educatingApp?: Maybe<Scalars['Boolean']>;
  educatingAppWholeApp?: Maybe<Scalars['Boolean']>;
  educatingAppService?: Maybe<Scalars['Boolean']>;
  educatingWiderTopics?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEntertaining?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducating?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducatingApp?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducatingAppWholeApp?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducatingAppService?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducatingWiderTopics?: Maybe<Scalars['Boolean']>;
  trend?: Maybe<Array<AuditedCreativeTrend>>;
  trendFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  trendFirstSecondDifferentTrend?: Maybe<Array<AuditedCreativeTrend>>;
  references?: Maybe<Array<AuditedCreativeReferences>>;
  sound?: Maybe<Array<AuditedCreativeSound>>;
  soundFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  soundFirstSecondDifferentSound?: Maybe<Array<AuditedCreativeSound>>;
  contentType?: Maybe<Array<AuditedCreativeContentType>>;
  contentTypeFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  contentTypeFirstSecondDifferentContentType?: Maybe<Array<AuditedCreativeContentType>>;
  financialBenefit?: Maybe<Array<AuditedCreativeFinancialBenefit>>;

  // *************** VISUAL FEATURES ***************
  brandedElements?: Maybe<Scalars['Boolean']>;
  brandedElementsFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  brandedElementsFirstSecondDifferentBrandedElements?: Maybe<Scalars['Boolean']>;
  mainAd?: Maybe<Array<AuditedCreativeVisualFeature>>;
  mainAdFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  mainAdFirstSecondDifferentVisualFeatures?: Maybe<Array<AuditedCreativeVisualFeature>>;
  endCard?: Maybe<Array<AuditedCreativeVisualFeature>>;
  ctaPlacement?: Maybe<AuditedCreativeCtaPlacement>;

  // *************** SCRIPT/COPY ***************
  subtitles?: Maybe<Scalars['Boolean']>;
  subtitlesFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  subtitlesFirstSecondDifferentSubtitles?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Scalars['String']>>;
  copyFeatures?: Maybe<Array<AuditedCreativeCopyFeature>>;
};

export type AuditedCreativeSearchCriteria = {
  templateId?: Maybe<Scalars['String']>;
};

export type AuditedCreativeUpdateRequest = {
  id: Scalars['String'];

  // *************** MAIN ***************
  authorId?: Maybe<Scalars['String']>;
  ugc?: Maybe<Scalars['Boolean']>;

  // *************** AD CONCEPT ***************
  memeTrend?: Maybe<Scalars['Boolean']>;
  aiContent?: Maybe<Scalars['Boolean']>;
  popularFormats?: Maybe<Array<AuditedCreativePopularFormat>>;
  popularFormatsFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  popularFormatsFirstSecondDifferentPopularFormats?: Maybe<Array<AuditedCreativePopularFormat>>;

  // *************** ASSET DETAILS ***************
  layout?: Maybe<AuditedCreativeLayout>;
  singleAssetFormatType?: Maybe<AuditedCreativeSingleAssetFormatType>;
  singleAssetType?: Maybe<AuditedCreativeSingleAssetType>;
  singleAssetSubType?: Maybe<AuditedCreativeSingleAssetSubType>;
  assetDetailsFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  assetDetailsFirstSecondDifferentAssetType?: Maybe<AuditedCreativeSingleAssetType>;
  assetDetailsFirstSecondDifferentAssetSubType?: Maybe<AuditedCreativeSingleAssetSubType>;
  assetFormat?: Maybe<AuditedCreativeType>;
  assetRatio?: Maybe<Scalars['String']>;
  videoLength?: Maybe<Scalars['Int']>;

  // *************** CONTENT ***************
  entertaining?: Maybe<Scalars['Boolean']>;
  educating?: Maybe<Scalars['Boolean']>;
  educatingApp?: Maybe<Scalars['Boolean']>;
  educatingAppWholeApp?: Maybe<Scalars['Boolean']>;
  educatingAppService?: Maybe<Scalars['Boolean']>;
  educatingWiderTopics?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEntertaining?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducating?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducatingApp?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducatingAppWholeApp?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducatingAppService?: Maybe<Scalars['Boolean']>;
  contentFirstSecondDifferentEducatingWiderTopics?: Maybe<Scalars['Boolean']>;
  trend?: Maybe<Array<AuditedCreativeTrend>>;
  trendFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  trendFirstSecondDifferentTrend?: Maybe<Array<AuditedCreativeTrend>>;
  references?: Maybe<Array<AuditedCreativeReferences>>;
  sound?: Maybe<Array<AuditedCreativeSound>>;
  soundFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  soundFirstSecondDifferentSound?: Maybe<Array<AuditedCreativeSound>>;
  contentType?: Maybe<Array<AuditedCreativeContentType>>;
  contentTypeFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  contentTypeFirstSecondDifferentContentType?: Maybe<Array<AuditedCreativeContentType>>;
  financialBenefit?: Maybe<Array<AuditedCreativeFinancialBenefit>>;

  // *************** VISUAL FEATURES ***************
  brandedElements?: Maybe<Scalars['Boolean']>;
  brandedElementsFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  brandedElementsFirstSecondDifferentBrandedElements?: Maybe<Scalars['Boolean']>;
  mainAd?: Maybe<Array<AuditedCreativeVisualFeature>>;
  mainAdFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  mainAdFirstSecondDifferentVisualFeatures?: Maybe<Array<AuditedCreativeVisualFeature>>;
  endCard?: Maybe<Array<AuditedCreativeVisualFeature>>;
  ctaPlacement?: Maybe<AuditedCreativeCtaPlacement>;

  // *************** SCRIPT/COPY ***************
  subtitles?: Maybe<Scalars['Boolean']>;
  subtitlesFirstSecondDifferent?: Maybe<Scalars['Boolean']>;
  subtitlesFirstSecondDifferentSubtitles?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Scalars['String']>>;
  copyFeatures?: Maybe<Array<AuditedCreativeCopyFeature>>;
};

export enum AuditedCreativeType {
  SINGLE_IMAGE = 'SINGLE_IMAGE',
  SINGLE_VIDEO = 'SINGLE_VIDEO',
  CAROUSEL = 'CAROUSEL',
  MULTI_SIZE_IMAGE = 'MULTI_SIZE_IMAGE',
  MULTI_SIZE_VIDEO = 'MULTI_SIZE_VIDEO',
}

export enum AuditedCreativeLayout {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  SQUARE = 'SQUARE',
}

export enum AuditedCreativePopularFormat {
  POPULAR_FORMATS_HOW_TO_VIDEO = 'POPULAR_FORMATS_HOW_TO_VIDEO',
  POPULAR_FORMATS_FIX_VIDEO = 'POPULAR_FORMATS_FIX_VIDEO',
  POPULAR_FORMATS_SPLIT_SCREEN = 'POPULAR_FORMATS_SPLIT_SCREEN',
  POPULAR_FORMATS_COME_WITH_ME = 'POPULAR_FORMATS_COME_WITH_ME',
  POPULAR_FORMATS_VLOG = 'POPULAR_FORMATS_VLOG',
  POPULAR_FORMATS_PROCESS_VIDEO = 'POPULAR_FORMATS_PROCESS_VIDEO',
  POPULAR_FORMATS_TEXT_ON_SCREEN = 'POPULAR_FORMATS_TEXT_ON_SCREEN',
  POPULAR_FORMATS_TUTORIAL_VIDEO = 'POPULAR_FORMATS_TUTORIAL_VIDEO',
  POPULAR_FORMATS_LIP_SYNC = 'POPULAR_FORMATS_LIP_SYNC',
  POPULAR_FORMATS_TIPS_VIDEO = 'POPULAR_FORMATS_TIPS_VIDEO',
  POPULAR_FORMATS_POV_VIDEOS = 'POPULAR_FORMATS_POV_VIDEOS',
  POPULAR_FORMATS_PRODUCT_REVIEW_VIDEOS = 'POPULAR_FORMATS_PRODUCT_REVIEW_VIDEOS',
  POPULAR_FORMATS_ASMR_VIDEOS = 'POPULAR_FORMATS_ASMR_VIDEOS',
  POPULAR_FORMATS_GREEN_SCREEN_EFFECT = 'POPULAR_FORMATS_GREEN_SCREEN_EFFECT',
  POPULAR_FORMATS_FILTERS_VIDEO = 'POPULAR_FORMATS_FILTERS_VIDEO',
  POPULAR_FORMATS_Q_A = 'POPULAR_FORMATS_Q_A',
}

export enum AuditedCreativeSingleAssetFormatType {
  MOTION = 'MOTION',
  STATIC = 'STATIC',
}

export enum AuditedCreativeSingleAssetType {
  NONE = 'NONE',
  CREATOR_FACE = 'CREATOR_FACE',
  THIRD_PERSON_POV = 'THIRD_PERSON_POV',
  CREATOR = 'CREATOR',
  MEME = 'MEME',
  DMS = 'DMS',
  IOS = 'IOS',
  MOTION = 'MOTION',
  VIDEO_PRODUCTION = 'VIDEO_PRODUCTION',
  UGC = 'UGC',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  ILLUSTRATIONS = 'ILLUSTRATIONS',
  GRAPHIC_DESIGN_BRANDED = 'GRAPHIC_DESIGN_BRANDED',
}

export enum AuditedCreativeSingleAssetSubType {
  NONE = 'NONE',
  FB = 'FB',
  IMESSAGE = 'IMESSAGE',
  INSTAGRAM = 'INSTAGRAM',
  SNAPCHAT = 'SNAPCHAT',
  TWITCH = 'TWITCH',
  CALENDAR = 'CALENDAR',
  NOTES = 'NOTES',
  REMINDER = 'REMINDER',
  NOTIFICATION = 'NOTIFICATION',
  TWO_D = 'TWO_D',
  THREE_D = 'THREE_D',
  BOTH = 'BOTH',
  CREATOR = 'CREATOR',
  THIRD_PERSON_POV = 'THIRD_PERSON_POV',
  STILL_IMAGE = 'STILL_IMAGE',
  PORTRAITS = 'PORTRAITS',
  REVIEWS = 'REVIEWS',
}

export enum AuditedCreativeTrend {
  PERSON = 'PERSON',
  TOPIC = 'TOPIC',
  SONG = 'SONG',
  EVENT = 'EVENT',
  MEME = 'MEME',
  FORMAT = 'FORMAT',
  TIKTOK = 'TIKTOK',
}

export enum AuditedCreativeReferences {
  FAMOUS_PEOPLE = 'FAMOUS_PEOPLE',
  BRANDS = 'BRANDS',
}

export enum AuditedCreativeSound {
  MUSIC = 'MUSIC',
  TIKTOK_VOICE = 'TIKTOK_VOICE',
  HUMAN_VOICE_COVER = 'HUMAN_VOICE_COVER',
  ASMR = 'ASMR',
}

export enum AuditedCreativeContentType {
  NOSTALGIC = 'NOSTALGIC',
  LIFEHACK = 'LIFEHACK',
  CHALLENGE = 'CHALLENGE',
  MEMES = 'MEMES',
  RELAX = 'RELAX',
}

export enum AuditedCreativeFinancialBenefit {
  PROMO_CODE = 'PROMO_CODE',
  DISCOUNT = 'DISCOUNT',
  OFFER = 'OFFER',
  BENEFIT = 'BENEFIT',
}

export enum AuditedCreativeVisualFeature {
  PAINTGUN_LOGO = 'PAINTGUN_LOGO',
  APP_SCREEN = 'APP_SCREEN',
  BANK_CARD = 'BANK_CARD',
  MONEY = 'MONEY',
  AR = 'AR',
  EMOJIS = 'EMOJIS',
  APP_STORES = 'APP_STORES',
}

export enum AuditedCreativeCopyFeature {
  PROMO_CODE = 'PROMO_CODE',
  BANK_DISCLAIMER = 'BANK_DISCLAIMER',
}

export type AuditedCreativeRole = {
  role: AuditedCreativeRoleType;
  userId: Scalars['String'];
};

export enum AuditedCreativeRoleType {
  NONE = 'NONE',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  CREATIVE_STRATEGIST = 'CREATIVE_STRATEGIST',
  COPYWRITER = 'COPYWRITER',
  GRAPHIC_DESIGNER = 'GRAPHIC_DESIGNER',
  MOTION_DESIGNER = 'MOTION_DESIGNER',
  ILLUSTRATOR = 'ILLUSTRATOR',
  MODEL = 'MODEL',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  VIDEOGRAPHER = 'VIDEOGRAPHER',
  CREATOR = 'CREATOR',
}

export enum AuditedCreativeCtaPlacement {
  TOP_LEFT = 'TOP_LEFT',
  TOP_CENTER = 'TOP_CENTER',
  TOP_RIGHT = 'TOP_RIGHT',
  MIDDLE_LEFT = 'MIDDLE_LEFT',
  MIDDLE_CENTER = 'MIDDLE_CENTER',
  MIDDLE_RIGHT = 'MIDDLE_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}
