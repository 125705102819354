/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AuditReport.module.scss';
import 'react-resizable/css/styles.css';
import AuditReportProvider, {
  AuditReportContext,
  AuditReportContextProps,
} from 'components/features/private/auditReport/AuditReportProvider';
import { copyToClipboard, getDomain, scrollToElement } from 'utils/windowUtils';
import clsx from 'classnames';
import { Button, DropdownInput } from 'components/common';
import { toast } from 'react-toastify';
import ToasterInfo from 'components/common/toasterInfo';
import { baseSuccessNotification } from 'utils/notificationUtils';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/img/arrowLeft.svg';
import { ReactComponent as ShareIcon } from 'assets/img/share.svg';
import moment from 'moment';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Pair } from 'types/types';
import { userHasSession } from 'utils/userUtils';
import { useTrackedSelector } from 'components/app/store';
import LoggedInFrame from '../loggedInFrame';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  AuditedCreativeContentType,
  AuditedCreativeCtaPlacement,
  AuditedCreativeFinancialBenefit,
  AuditedCreativeSingleAssetSubType,
  AuditedCreativeSingleAssetType,
  AuditedCreativeSound,
  AuditedCreativeVisualFeature,
} from 'types/audit/auditedCreativeTypes.d';
import { Empty } from 'antd';

const COLORS = [
  '#7AB5D8',
  '#7193D6',
  '#6971D5',
  '#7C68D5',
  '#9A6AD5',
  '#BA6CD6',
  '#CD6EC9',
  '#CD6EA8',
  '#CD6E88',
  '#CD706B',
  '#D1906E',
  '#D5B171',
  '#DAD276',
  '#C8DB77',
  '#ADDA76',
  '#94DA75',
  '#86D980',
  '#86D99E',
  '#86D9BD',
  '#85D7DB',
];

const AuditReport = (): JSX.Element => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const contentRef = useRef(null);
  const firstSecondRef = useRef(null);
  const assetDetailsRef = useRef(null);
  const contentsRef = useRef(null);
  const visualFeaturesRef = useRef(null);
  const copyScriptRef = useRef(null);
  const summaryRef = useRef(null);

  const {
    auth: { user },
  } = useTrackedSelector();

  const {
    template,
    report,
    creatives,
    onBackToAudit,
    templates,
    selectedTemplateId,
    onSaveReport,
    onTemplateSelected,
  } = useContext(AuditReportContext) as AuditReportContextProps;

  const [firstSecondAnalysisEditorState, setFirstSecondAnalysisEditorState] = useState(() => EditorState.createEmpty());
  const [firstSecondAnalysisEditorInitialised, setFirstSecondAnalysisEditorInitialised] = useState(false);

  const [assetDetailsEditorState, setAssetDetailsEditorState] = useState(() => EditorState.createEmpty());
  const [assetDetailsEditorInitialised, setAssetDetailsEditorInitialised] = useState(false);

  const [visualFeaturesEditorState, setVisualFeaturesEditorState] = useState(() => EditorState.createEmpty());
  const [visualFeaturesEditorInitialised, setVisualFeaturesEditorInitialised] = useState(false);

  const [copyScriptScriptEditorState, setCopyScriptScriptEditorState] = useState(() => EditorState.createEmpty());
  const [copyScriptScriptEditorInitialised, setCopyScriptScriptEditorInitialised] = useState(false);

  const [adConceptEditorState, setAdConceptEditorState] = useState(() => EditorState.createEmpty());
  const [adConceptEditorInitialised, setAdConceptEditorInitialised] = useState(false);

  const [insightsEditorState, setInsightsEditorState] = useState(() => EditorState.createEmpty());
  const [insightsEditorInitialised, setInsightsEditorInitialised] = useState(false);

  const [recommendationsEditorState, setRecommendationsEditorState] = useState(() => EditorState.createEmpty());
  const [recommendationsEditorInitialised, setRecommendationsEditorInitialised] = useState(false);

  const [currentReport, setCurrentReport] = useState(report);
  const [activeTabId, setActiveTabId] = useState('0');

  const hasSession = useMemo(() => userHasSession(user), [user]);

  const dateFormat = useMemo(() => t('DATE_AND_TIME.DATE_FORMAT_HUMAN'), [t]);

  const tabs = useMemo(() => {
    return [
      { title: t('PRIVATE.USER.AUDIT_REPORT.FIRST_SECOND'), active: activeTabId === '0', id: '0' },
      { title: t('PRIVATE.USER.AUDIT_REPORT.ASSET_DETAILS'), active: activeTabId === '1', id: '1' },
      { title: t('PRIVATE.USER.AUDIT_REPORT.CONTENTS'), active: activeTabId === '2', id: '2' },
      { title: t('PRIVATE.USER.AUDIT_REPORT.VISUAL_FEATURES'), active: activeTabId === '3', id: '3' },
      { title: t('PRIVATE.USER.AUDIT_REPORT.COPY_SCRIPT'), active: activeTabId === '4', id: '4' },
      { title: t('PRIVATE.USER.AUDIT_REPORT.SUMMARY'), active: activeTabId === '5', id: '5' },
    ];
  }, [activeTabId, t]);

  const handleTabClick = useCallback((id: string) => {
    setActiveTabId(id);

    let ref = null;
    switch (id) {
      case '0':
        ref = firstSecondRef;
        break;
      case '1':
        ref = assetDetailsRef;
        break;
      case '2':
        ref = contentsRef;
        break;
      case '3':
        ref = visualFeaturesRef;
        break;
      case '4':
        ref = copyScriptRef;
        break;
      case '5':
        ref = summaryRef;
        break;
    }

    if (ref) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const offset = document.getElementById(ref?.current?.id)?.offsetTop - 180;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      scrollToElement(contentRef?.current?.id, offset);
    }
  }, []);

  const onSave = useCallback(() => {
    if (!currentReport) return;

    const firstSecondAnalysis = draftToHtml(convertToRaw(firstSecondAnalysisEditorState.getCurrentContent()));
    const assetDetails = draftToHtml(convertToRaw(assetDetailsEditorState.getCurrentContent()));
    const visualFeatures = draftToHtml(convertToRaw(visualFeaturesEditorState.getCurrentContent()));
    const copyScript = draftToHtml(convertToRaw(copyScriptScriptEditorState.getCurrentContent()));
    const adConcept = draftToHtml(convertToRaw(adConceptEditorState.getCurrentContent()));
    const insights = draftToHtml(convertToRaw(insightsEditorState.getCurrentContent()));
    const recommendations = draftToHtml(convertToRaw(recommendationsEditorState.getCurrentContent()));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setCurrentReport((prev) => ({
      ...prev,
      firstSecondAnalysis,
      assetDetails,
      visualFeatures,
      copyScript,
      adConcept,
      insights,
      recommendations,
    }));

    const toSave = {
      ...currentReport,
      firstSecondAnalysis,
      assetDetails,
      visualFeatures,
      copyScript,
      adConcept,
      insights,
      recommendations,
    };
    onSaveReport(toSave);
  }, [
    adConceptEditorState,
    assetDetailsEditorState,
    copyScriptScriptEditorState,
    currentReport,
    firstSecondAnalysisEditorState,
    insightsEditorState,
    onSaveReport,
    recommendationsEditorState,
    visualFeaturesEditorState,
  ]);

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const onShare = useCallback(() => {
    const url = `${getDomain()}/#${pathname}${search}`;
    copyToClipboard(url);
    showSuccessNotification(t('COMMON.COPIED_TO_CLIPBOARD_LINK', { content: url }));
  }, [pathname, search, showSuccessNotification, t]);

  // @ts-ignore
  const chartLabel = useCallback(({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }, []);

  const getLegend = useCallback((labels: string[]) => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '1rem' }}>
        {[...new Set(labels)].map((label, index) => (
          <div key={label} style={{ display: 'flex', alignItems: 'center', marginRight: '1.5rem' }}>
            <div
              style={{
                height: '0.5625rem',
                width: '0.5625rem',
                borderRadius: '6px',
                backgroundColor: COLORS[index],
                marginRight: '0.5rem',
                marginBottom: '1px',
              }}
            />
            <div style={{ fontSize: '0.75rem', lineHeight: '1rem' }}>{label}</div>
          </div>
        ))}
      </div>
    );
  }, []);

  const getPieChart = useCallback(
    (
      title: string,
      labels: string[],
      data: { name: string; value: number | null }[],
      innerRadius: number | undefined,
      firstSecond = false,
    ) => (
      <div className={clsx(styles.card, styles.chartCard)}>
        <div>
          <div className={styles.cardTitle}>
            <span>{title}</span>
            {firstSecond && (
              <span
                style={{
                  marginLeft: '1rem',
                  padding: '2px 4px 2px 4px',
                  borderRadius: '2px',
                  backgroundColor: '#EEFED1',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                {t('PRIVATE.USER.AUDIT_REPORT.FIRST_SECOND')}
              </span>
            )}
          </div>
          <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.BASED_ON_CREATIVES')}</div>
        </div>
        <div style={{ width: '100%', height: '100%' }}>
          {!!data.length ? (
            <ResponsiveContainer width="100%" height="75%">
              <PieChart>
                <Tooltip />
                <Pie
                  data={data.map((i: { name: string; value: number | null }, index: number) => ({ ...i, index }))}
                  dataKey="value"
                  labelLine={false}
                  label={chartLabel}
                  innerRadius={innerRadius}
                >
                  {data
                    .map((i: { name: string; value: number | null }, index: number) => ({ ...i, index }))
                    .map((entry: { name: string; value: number | null }, index: number) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Empty style={{ paddingTop: '4rem', height: '75%' }} />
          )}
          {getLegend(labels)}
        </div>
      </div>
    ),
    [chartLabel, getLegend, t],
  );

  const getBarChart = useCallback(
    (title: string, labels: string[], data: { name: string; value: number | null }[]) => (
      <div className={clsx(styles.card, styles.chartCard)}>
        <div>
          <div className={styles.cardTitle}>
            <span>{title}</span>
          </div>
          <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.BASED_ON_CREATIVES')}</div>
        </div>
        <div style={{ width: '100%', height: '100%' }}>
          {!!data?.length ? (
            <ResponsiveContainer width="100%" height="80%">
              <BarChart data={data.map((i, index) => ({ ...i, index }))} margin={{ left: -20, top: 20 }}>
                <CartesianGrid strokeDasharray="3" vertical={false} />
                <XAxis dataKey="name" style={{ fontSize: '0.5625rem' }} axisLine={false} tickLine={false} />
                <YAxis style={{ fontSize: '0.8rem' }} axisLine={false} tickLine={false} />
                <Tooltip />
                <Bar
                  dataKey="value"
                  shape={(props) => {
                    props.radius = [4, 4, 0, 0];
                    props.fill = COLORS[props.index];
                    return <Rectangle {...props} />;
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Empty style={{ paddingTop: '4rem', height: '75%' }} />
          )}
          {getLegend(labels)}
        </div>
      </div>
    ),
    [getLegend, t],
  );

  // init rich editors
  useEffect(() => {
    if (!currentReport) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setStateValue = (raw: string, setter: (value: any) => void) => {
      const contentBlock = htmlToDraft(raw);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setter(EditorState.createWithContent(contentState));
      } else {
        setter(EditorState.createEmpty());
      }
    };

    if (!firstSecondAnalysisEditorInitialised) {
      setFirstSecondAnalysisEditorInitialised(true);
      setStateValue(currentReport?.firstSecondAnalysis || '', setFirstSecondAnalysisEditorState);
    }
    if (!assetDetailsEditorInitialised) {
      setAssetDetailsEditorInitialised(true);
      setStateValue(currentReport?.assetDetails || '', setAssetDetailsEditorState);
    }
    if (!visualFeaturesEditorInitialised) {
      setVisualFeaturesEditorInitialised(true);
      setStateValue(currentReport?.visualFeatures || '', setVisualFeaturesEditorState);
    }
    if (!copyScriptScriptEditorInitialised) {
      setCopyScriptScriptEditorInitialised(true);
      setStateValue(currentReport?.copyScript || '', setCopyScriptScriptEditorState);
    }
    if (!adConceptEditorInitialised) {
      setAdConceptEditorInitialised(true);
      setStateValue(currentReport?.adConcept || '', setAdConceptEditorState);
    }
    if (!insightsEditorInitialised) {
      setInsightsEditorInitialised(true);
      setStateValue(currentReport?.insights || '', setInsightsEditorState);
    }
    if (!recommendationsEditorInitialised) {
      setRecommendationsEditorInitialised(true);
      setStateValue(currentReport?.recommendations || '', setRecommendationsEditorState);
    }
  }, [
    adConceptEditorInitialised,
    assetDetailsEditorInitialised,
    copyScriptScriptEditorInitialised,
    currentReport,
    firstSecondAnalysisEditorInitialised,
    insightsEditorInitialised,
    recommendationsEditorInitialised,
    visualFeaturesEditorInitialised,
  ]);

  useEffect(() => {
    setCurrentReport(report);
  }, [report]);

  if (!template || !report) return <></>;

  return (
    <LoggedInFrame color="black">
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarHeader}>{t('PRIVATE.USER.AUDIT_REPORT.REPORT')}</div>
          {tabs.map(({ title, active, id }) => (
            <div
              key={id}
              className={clsx(styles.sidebarItem, { [styles.sidebarItemActive]: active })}
              onClick={() => {
                if (!active) handleTabClick(id);
              }}
            >
              {title}
            </div>
          ))}
        </div>
        <div className={styles.contentWrapper}>
          {hasSession && (
            <div className={styles.back} onClick={onBackToAudit}>
              <ArrowLeftIcon />
              {t('PRIVATE.USER.AUDIT_REPORT.BACK')}
            </div>
          )}
          <div className={styles.header}>
            <div className={styles.headerStart}>
              <span>{t('PRIVATE.USER.AUDIT_REPORT.CREATIVE_AUDIT')}</span>
              {!hasSession && <span>: {template.name}</span>}
            </div>
            <div className={styles.headerEnd}>
              {hasSession && <div className={styles.headerEndLabel}>{t('PRIVATE.USER.AUDIT_REPORT.REPORT')}</div>}
              {hasSession && (
                <DropdownInput
                  initialValue={{ id: template.id, name: template.name }}
                  variants={(templates?.length
                    ? templates
                    : [
                        {
                          id: report.templateId,
                          name: report.templateName,
                        },
                      ]
                  ).map((i) => ({ id: i.id, name: i.name, selected: i.id === selectedTemplateId }))}
                  takeValue={(_, id) => onTemplateSelected(id)}
                  className={styles.templateName}
                  dropDownWrapperClassname={styles.selectorInputWrapper}
                  menuContainerClassname={styles.dropdownInputWrapper}
                />
              )}
              <div className={styles.headerEndShare} onClick={onShare}>
                <ShareIcon />
                {t('PRIVATE.USER.AUDIT_REPORT.SHARE')}
              </div>
            </div>
          </div>

          <div className={styles.content} ref={contentRef} id="contentSection">
            {/* report details */}
            <div className={styles.firstRow}>
              <div className={clsx(styles.card, styles.creatives)}>
                <div className={styles.cardTitle}>{t('PRIVATE.USER.AUDIT_REPORT.NUMBER_OF_CREATIVES')}</div>
                <div className={styles.creativesNumber}>{creatives.length}</div>
              </div>

              <div className={clsx(styles.card, styles.report)}>
                <div className={styles.cardTitle}>{t('PRIVATE.USER.AUDIT_REPORT.REPORT')}</div>
                <div className={styles.reportDetails}>
                  {[
                    {
                      key: t('PRIVATE.USER.AUDIT.DATE'),
                      value: `${moment(template.startDate).format(dateFormat)} - ${moment(template.endDate).format(
                        dateFormat,
                      )}`,
                    },
                    {
                      key: t('PRIVATE.USER.AUDIT.COUNTRIES'),
                      value: !!template.countries?.length ? template.countries.join(', ') : null,
                    },
                    {
                      key: t('PRIVATE.USER.AUDIT.NETWORKS'),
                      value: !!template.networks?.length ? template.networks.join(', ') : null,
                    },
                    {
                      key: t('PRIVATE.USER.AUDIT.PLACEMENT'),
                      value: !!template.placement?.length ? template.placement : null,
                    },
                    {
                      key: t('PRIVATE.USER.AUDIT.TYPES'),
                      value: !!template.adTypes?.length ? template.adTypes.join(', ') : null,
                    },
                  ].map(({ key, value }) => (
                    <div key={key}>
                      <span style={{ marginRight: '0.5rem' }}>{key}:</span>
                      <span style={{ marginRight: '1rem', fontWeight: 700 }}>
                        {value || t('PRIVATE.USER.AUDIT.ALL')}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* top 50 creatives */}
            <div className={clsx(styles.card, styles.top50Creatives)}>
              <div>
                <div className={styles.cardTitle}>{t('PRIVATE.USER.AUDIT_REPORT.TOP_CREATIVES')}</div>
                <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.BASED_ON_ST_DATA')}</div>
              </div>
              <div className={styles.top50CreativesGrid}>
                {[
                  creatives.map((i, index) => (
                    <div key={index} className={styles.top50CreativesGridItem}>
                      <img src={i} alt="asset" />
                    </div>
                  )),
                ]}
              </div>
            </div>

            <div className={styles.sectionName} ref={firstSecondRef} id="firstSecondSection">
              {t('PRIVATE.USER.AUDIT_REPORT.FIRST_SECOND')}
            </div>

            <div className={styles.chartCards}>
              {[
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.ASSET_TYPE'),
                  labels: Object.values(AuditedCreativeSingleAssetType)
                    .filter((i) => i !== AuditedCreativeSingleAssetType.NONE)
                    .map((i) => t(`ASSET_TYPE.${i}`)),
                  data:
                    report.firstSecondAssetType?.map((i: Pair) => ({
                      name: t(`ASSET_TYPE.${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.ASSET_SUBTYPE'),
                  labels: Object.values(AuditedCreativeSingleAssetSubType)
                    .filter((i) => i !== AuditedCreativeSingleAssetSubType.NONE)
                    .map((i) => t(`ASSET_SUB_TYPE.${i}`)),
                  data:
                    report.firstSecondAssetSubType?.map((i: Pair) => ({
                      name: t(`ASSET_SUB_TYPE.${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.SOUND'),
                  labels: Object.values(AuditedCreativeSound).map((i) => t(`PRIVATE.USER.AUDIT.SOUND_${i}`)),
                  data:
                    report.firstSecondSound?.map((i: Pair) => ({
                      name: t(`PRIVATE.USER.AUDIT.SOUND_${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.CONTENT_TYPE'),
                  labels: Object.values(AuditedCreativeContentType).map((i) =>
                    t(`PRIVATE.USER.AUDIT.CONTENT_TYPE_${i}`),
                  ),
                  data:
                    report.firstSecondContentType?.map((i: Pair) => ({
                      name: t(`PRIVATE.USER.AUDIT.CONTENT_TYPE_${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.MAIN_CARD_TOP_5_VISUAL_FEATURES'),
                  labels: Object.values(AuditedCreativeVisualFeature).map((i) => t(`PRIVATE.USER.AUDIT.${i}`)),
                  data:
                    report.firstSecondTop5VisualFeatures?.map((i: Pair) => ({
                      name: t(`PRIVATE.USER.AUDIT.${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.BRANDED_ELEMENTS'),
                  labels: [t('COMMON.WITH'), t('COMMON.WITHOUT')],
                  data:
                    report.firstSecondBrandedElements?.map((i: Pair) => ({
                      name: i.first ? t('COMMON.WITH') : t('COMMON.WITHOUT'),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: undefined,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.SUBTITLES'),
                  labels: [t('COMMON.WITH'), t('COMMON.WITHOUT')],
                  data:
                    report.firstSecondSubtitles?.map((i: Pair) => ({
                      name: i.first ? t('COMMON.WITH') : t('COMMON.WITHOUT'),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: undefined,
                },
              ].map(({ title, labels, data, innerRadius }) => getPieChart(title, labels, data, innerRadius, true))}
            </div>

            {hasSession ? (
              <div className={styles.richEditorCard}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.FIRST_SECOND_ANALYSIS')}</span>
                  </div>
                  <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    // editorStyle={{ height: '10rem' }}
                    editorState={firstSecondAnalysisEditorState}
                    onEditorStateChange={(e) => {
                      setFirstSecondAnalysisEditorState(e);
                    }}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
                <Button className={styles.save} onClick={onSave}>
                  {t('COMMON.SAVE')}
                </Button>
              </div>
            ) : (
              <div className={styles.richEditorCardReadonly}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.FIRST_SECOND_ANALYSIS')}</span>
                  </div>
                  <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    readOnly={true}
                    toolbarHidden={true}
                    editorState={firstSecondAnalysisEditorState}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
              </div>
            )}

            <div className={styles.sectionName} ref={assetDetailsRef} id="assetDetailsSection">
              {t('PRIVATE.USER.AUDIT_REPORT.ASSET_DETAILS')}
            </div>

            <div className={styles.chartCards}>
              {[
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.ASSET_TYPE'),
                  labels: Object.values(AuditedCreativeSingleAssetType)
                    .filter((i) => i !== AuditedCreativeSingleAssetType.NONE)
                    .map((i) => t(`ASSET_TYPE.${i}`)),
                  data:
                    report.assetType?.map((i: Pair) => ({
                      name: t(`ASSET_TYPE.${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.ASSET_SUBTYPE'),
                  labels: Object.values(AuditedCreativeSingleAssetSubType)
                    .filter((i) => i !== AuditedCreativeSingleAssetSubType.NONE)
                    .map((i) => t(`ASSET_SUB_TYPE.${i}`)),
                  data:
                    report.assetSubType?.map((i: Pair) => ({
                      name: t(`ASSET_SUB_TYPE.${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
              ].map(({ title, labels, data, innerRadius }) => getPieChart(title, labels, data, innerRadius))}
            </div>

            <div className={styles.sectionName} ref={contentsRef} id="contentsSection">
              {t('PRIVATE.USER.AUDIT_REPORT.CONTENTS')}
            </div>

            <div className={styles.chartCards}>
              {[
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.CONTENT'),
                  labels: [t(`PRIVATE.USER.AUDIT.CONTENT_ENTERTAINING`), t(`PRIVATE.USER.AUDIT.CONTENT_EDUCATING`)],
                  data:
                    report.content?.map((i: Pair) => ({
                      name: t(`PRIVATE.USER.AUDIT.${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.SOUND'),
                  labels: Object.values(AuditedCreativeSound).map((i) => t(`PRIVATE.USER.AUDIT.SOUND_${i}`)),
                  data:
                    report.sound?.map((i: Pair) => ({
                      name: t(`PRIVATE.USER.AUDIT.SOUND_${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.CONTENT_TYPE'),
                  labels: Object.values(AuditedCreativeContentType).map((i) =>
                    t(`PRIVATE.USER.AUDIT.CONTENT_TYPE_${i}`),
                  ),
                  data:
                    report.contentType?.map((i: Pair) => ({
                      name: t(`PRIVATE.USER.AUDIT.CONTENT_TYPE_${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.FINANCIAL_BENEFIT_VISIBLE'),
                  labels: Object.values(AuditedCreativeFinancialBenefit).map((i) =>
                    t(`PRIVATE.USER.AUDIT.FINANCIAL_BENEFIT_${i}`),
                  ),
                  data:
                    report.financialBenefit?.map((i: Pair) => ({
                      name: t(`PRIVATE.USER.AUDIT.FINANCIAL_BENEFIT_${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
              ].map(({ title, labels, data, innerRadius }) => getPieChart(title, labels, data, innerRadius))}
            </div>

            {hasSession ? (
              <div className={styles.richEditorCard}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.ASSET_DETAILS')}</span>
                  </div>
                  <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    // editorStyle={{ height: '10rem' }}
                    editorState={assetDetailsEditorState}
                    onEditorStateChange={(e) => {
                      setAssetDetailsEditorState(e);
                    }}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
                <Button className={styles.save} onClick={onSave}>
                  {t('COMMON.SAVE')}
                </Button>
              </div>
            ) : (
              <div className={styles.richEditorCardReadonly}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.ASSET_DETAILS')}</span>
                  </div>
                  <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    readOnly={true}
                    toolbarHidden={true}
                    editorState={assetDetailsEditorState}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
              </div>
            )}

            <div className={styles.sectionName} ref={visualFeaturesRef} id="visualFeaturesSection">
              {t('PRIVATE.USER.AUDIT_REPORT.VISUAL_FEATURES')}
            </div>

            <div className={styles.chartCards}>
              {[
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.BRANDED_ELEMENTS'),
                  labels: [t('COMMON.WITH'), t('COMMON.WITHOUT')],
                  data:
                    report.brandedElements?.map((i: Pair) => ({
                      name: i.first ? t('COMMON.WITH') : t('COMMON.WITHOUT'),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.MAIN_CARD_TOP_5_VISUAL_FEATURES'),
                  labels: Object.values(AuditedCreativeVisualFeature).map((i) => t(`PRIVATE.USER.AUDIT.${i}`)),
                  data:
                    report.mainCardTop5VisualFeatures?.map((i: Pair) => ({
                      name: t(`PRIVATE.USER.AUDIT.${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.END_CARD_TOP_5_VISUAL_FEATURES'),
                  labels: Object.values(AuditedCreativeVisualFeature).map((i) => t(`PRIVATE.USER.AUDIT.${i}`)),
                  data:
                    report.endCardTop5VisualFeatures?.map((i: Pair) => ({
                      name: t(`PRIVATE.USER.AUDIT.${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
              ].map(({ title, labels, data, innerRadius }) => getPieChart(title, labels, data, innerRadius))}

              {[
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.TOP_CTA_PLACEMENT'),
                  labels: Object.values(AuditedCreativeCtaPlacement).map((i) => t(`CTA_PLACEMENT.${i}`)),
                  data:
                    report.topCtaPlacement?.map((i: Pair) => ({
                      name: t(`CTA_PLACEMENT.${i.first}`),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                },
              ].map(({ title, labels, data }) => getBarChart(title, labels, data))}
            </div>

            {hasSession ? (
              <div className={styles.richEditorCard}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.VISUAL_FEATURES')}</span>
                  </div>
                  <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    // editorStyle={{ height: '10rem' }}
                    editorState={visualFeaturesEditorState}
                    onEditorStateChange={(e) => {
                      setVisualFeaturesEditorState(e);
                    }}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
                <Button className={styles.save} onClick={onSave}>
                  {t('COMMON.SAVE')}
                </Button>
              </div>
            ) : (
              <div className={styles.richEditorCardReadonly}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.VISUAL_FEATURES')}</span>
                  </div>
                  <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    readOnly={true}
                    toolbarHidden={true}
                    editorState={visualFeaturesEditorState}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
              </div>
            )}

            <div className={styles.sectionName} ref={copyScriptRef} id="copyScriptSection">
              {t('PRIVATE.USER.AUDIT_REPORT.COPY_SCRIPT')}
            </div>

            <div className={styles.chartCards}>
              {[
                {
                  title: t('PRIVATE.USER.AUDIT_REPORT.SUBTITLES'),
                  labels: [t('COMMON.WITH'), t('COMMON.WITHOUT')],
                  data:
                    report.subtitles?.map((i: Pair) => ({
                      name: i.first ? t('COMMON.WITH') : t('COMMON.WITHOUT'),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
              ].map(({ title, labels, data, innerRadius }) => getPieChart(title, labels, data, innerRadius))}
            </div>

            {hasSession ? (
              <div className={styles.richEditorCard}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.COPY_SCRIPT')}</span>
                  </div>
                  <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    editorState={copyScriptScriptEditorState}
                    onEditorStateChange={(e) => {
                      setCopyScriptScriptEditorState(e);
                    }}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
                <Button className={styles.save} onClick={onSave}>
                  {t('COMMON.SAVE')}
                </Button>
              </div>
            ) : (
              <div className={styles.richEditorCardReadonly}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.COPY_SCRIPT')}</span>
                  </div>
                  <div className={styles.cardSubtitle}>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    readOnly={true}
                    toolbarHidden={true}
                    editorState={copyScriptScriptEditorState}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
              </div>
            )}

            <div className={styles.sectionName} ref={summaryRef} id="summarySection">
              {t('PRIVATE.USER.AUDIT_REPORT.AD_CONCEPT')}
            </div>

            <div className={styles.chartCards}>
              {[
                {
                  title: t('PRIVATE.USER.AUDIT.TREND_MEME'),
                  labels: [t('COMMON.YES'), t('COMMON.NO')],
                  data:
                    report.memeTrend?.map((i: Pair) => ({
                      name: i.first ? t('COMMON.YES') : t('COMMON.NO'),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
                {
                  title: t('PRIVATE.USER.AUDIT.AI_CONTENT'),
                  labels: [t('COMMON.WITH'), t('COMMON.WITHOUT')],
                  data:
                    report.aiContent?.map((i: Pair) => ({
                      name: i.first ? t('COMMON.WITH') : t('COMMON.WITHOUT'),
                      value: i.second ? parseInt(i.second) : 0,
                    })) || [],
                  innerRadius: 50,
                },
              ].map(({ title, labels, data, innerRadius }) => getPieChart(title, labels, data, innerRadius))}
            </div>

            <div className={clsx(styles.sectionName, styles.summary)} ref={summaryRef} id="summarySection">
              {t('PRIVATE.USER.AUDIT_REPORT.SUMMARY')}
            </div>

            {hasSession ? (
              <div className={styles.richEditorCard}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</span>
                  </div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    editorState={insightsEditorState}
                    onEditorStateChange={(e) => {
                      setInsightsEditorState(e);
                    }}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
                <Button className={styles.saveWithoutSubtitle} onClick={onSave}>
                  {t('COMMON.SAVE')}
                </Button>
              </div>
            ) : (
              <div className={styles.richEditorCardReadonly}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.INSIGHTS')}</span>
                  </div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    readOnly={true}
                    toolbarHidden={true}
                    editorState={insightsEditorState}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
              </div>
            )}

            {hasSession ? (
              <div className={styles.richEditorCard}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.RECOMMENDATIONS')}</span>
                  </div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    editorState={recommendationsEditorState}
                    onEditorStateChange={(e) => {
                      setRecommendationsEditorState(e);
                    }}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
                <Button className={styles.saveWithoutSubtitle} onClick={onSave}>
                  {t('COMMON.SAVE')}
                </Button>
              </div>
            ) : (
              <div className={styles.richEditorCardReadonly}>
                <div>
                  <div className={styles.cardTitle}>
                    <span>{t('PRIVATE.USER.AUDIT_REPORT.RECOMMENDATIONS')}</span>
                  </div>
                </div>
                <div className={styles.richEditor}>
                  <Editor
                    readOnly={true}
                    toolbarHidden={true}
                    editorState={recommendationsEditorState}
                    toolbarClassName={styles.richEditorToolbar}
                    editorClassName={styles.richEditorEditor}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </LoggedInFrame>
  );
};

export default function AuditReportWrapper(): JSX.Element {
  return (
    <AuditReportProvider>
      <AuditReport />
    </AuditReportProvider>
  );
}
